import React from "react"
import { Link } from "gatsby"
import {
  LANDING,
  SIGN_IN,
  CONTACT,
  STRUCTURE,
  SUPPORT,
} from "../../../../constants/routes"

const NavigationNonAuth = () => (
  <div className="navbar">
    <div className="navbar__inner">
      <div className="navbar__left">
        <div className="navbar__logo">
          <Link to={LANDING}>
            <img src={require("../../../../assets/Logo.jpg")} alt="logo" />
          </Link>
        </div>
        <div className="navbar__links">
          <div className="navbar__link">
            <Link to={STRUCTURE}>How it works</Link>
          </div>
          <div className="navbar__link">
            <Link to={CONTACT}>Contact</Link>
          </div>
          <div className="navbar__link">
            <Link to={SUPPORT}>Donate</Link>
          </div>
        </div>
      </div>

      <div className="navbar__user">
        <div className="navbar__link">
          <Link to={SIGN_IN}>Facilitator Sign In</Link>
        </div>
      </div>
    </div>
  </div>
)

export default NavigationNonAuth
