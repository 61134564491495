export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const RESOURCES = "/resources";
export const MINISTRY = "/ministry";
export const CREATE_USER = "/create-user";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const REPORT = "/report";
export const PUBLIC_MINISTRY = "/publicMinistry";
export const CONTACT = "/contact";
export const SUPPORT = "/support";
export const MAPS = "/maps";
export const STRUCTURE = "/structure";
export const TESTIMONIES = "/testimonies";
