export default {
  production: {
    apiKey: "AIzaSyB6foBJ9Nmbu6u7T5kAwu6JH4Wq1_oAgqw",
    authDomain: "sharebibles.firebaseapp.com",
    databaseURL: "https://sharebibles.firebaseio.com",
    projectId: "sharebibles",
    storageBucket: "sharebibles.appspot.com",
    messagingSenderId: "152573539716",
    appId: "1:152573539716:web:be2d673ed59121df35d9d1",
  },
  staging: {
    apiKey: "AIzaSyD9lGv-LmeRJhXnV3OJhS5avWasRet-OOs",
    authDomain: "share-bibles-staging.firebaseapp.com",
    databaseURL: "https://share-bibles-staging.firebaseio.com",
    projectId: "share-bibles-staging",
    storageBucket: "share-bibles-staging.appspot.com",
    messagingSenderId: "58289620545",
    appId: "1:58289620545:web:c1fbd729795683290b68c0",
  },
};
