import React from "react";
import { Link } from "gatsby";
import {
  LANDING,
  HOME,
  ACCOUNT,
  CREATE_USER,
  RESOURCES,
  MINISTRY,
  CONTACT,
  SUPPORT,
  MAPS,
  STRUCTURE,
  TESTIMONIES,
} from "../../../../constants/routes";
import SignOutButton from "./SignOutButton";
import Badge from "@material-ui/core/Badge";

const NavigationAuth = () => (
  <div className="navbar">
    <div className="navbar__inner">
      <div className="navbar__left">
        <div className="navbar__logo">
          <Link to={LANDING}>
            <img src={require("../../../../assets/Logo.jpg")} alt="logo" />
          </Link>
        </div>
        <div className="navbar__links">
          {/* <div className="navbar__link">
            <Link to={LANDING}>Landing</Link>
          </div> */}
          <div className="navbar__link">
            <Link to={HOME}>Dashboard</Link>
          </div>
          <div className="navbar__link">
            <Link to={ACCOUNT}>Account</Link>
          </div>
          <div className="navbar__link">
            <Link to={MINISTRY}>Ministry</Link>
          </div>
          <div className="navbar__link">
            <Link to={RESOURCES}>Resources</Link>
          </div>
          <div className="navbar__link">
            <Link to={CREATE_USER}>Create User</Link>
          </div>
          <div className="navbar__link">
            <Link to={MAPS}>Maps</Link>
          </div>
          <div className="navbar__link beta">
              <Link to={TESTIMONIES}>Testimonies</Link>
          </div>
          <div className="navbar__link">
            <Link to={STRUCTURE}>How it works</Link>
          </div>
          <div className="navbar__link">
            <Link to={CONTACT}>Contact</Link>
          </div>
          <div className="navbar__link">
            <Link to={SUPPORT}>Donate</Link>
          </div>
          {/* <div className="navbar__link navbar__link--github">
            <span className="navbar__link--github__a">
              Created By{" "}
              <a href="https://github.com/ovidiumihaibelciug">
                Ovidiu Mihai Belciug
              </a>
            </span>
            <div>
              <iframe
                src="https://ghbtns.com/github-btn.html?user=ovidiumihaibelciug&type=follow&count=true"
                frameborder="0"
                scrolling="0"
                width="170px"
                height="20px"
              />
            </div>
            <div>
              <iframe
                src="https://ghbtns.com/github-btn.html?user=ovidiumihaibelciug&repo=gatsby-firebase-starter&type=star&count=true"
                frameborder="0"
                scrolling="0"
                width="170px"
                height="20px"
              />
            </div>
          </div> */}
        </div>
      </div>

      <div className="navbar__user">
        <div className="navbar__item">
          <SignOutButton />
        </div>
      </div>
    </div>
  </div>
);

export default NavigationAuth;
